import { api } from '@/api'
export default {
    name: 'pageView',

    created () {
        // 判断是否有type 参数
        if (!this.$route.query.type) {
            this.$toast('缺少类型参数')
            if (_global.route) return this.$router.push(_global.route)
            return setTimeout(() => { this.$router.push({ name: 'Default' }) }, 500);
        }

        // 获取数据
        this.handleGetData()
    },

    methods: {
        handleGetData () {
            if (this.isAjax) return
            this.isAjax = true
            api.pageView({
                type: this.$route.query.type
            }).then(res => {
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message);
                this.data = res.data
            }).catch(err => {
                console.log(err)
                this.isAjax = false
            })
        }
    },

    data() {
        return {
            isAjax: false,
            data: null,
        }
    }

}